export default function ExitFullScreenIcon(){
    return (
        <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.16337 0C8.57856 0 8.10458 0.488364 8.10458 1.09091V6.47891L1.78464 0.350546C1.3583 -0.0621816 0.688792 -0.041818 0.287863 0.397455C-0.113067 0.836 -0.0925968 1.52618 0.333038 1.93927L6.56933 7.98655H1.41195C0.827141 7.98655 0.353155 8.47491 0.353155 9.07746C0.353155 9.68 0.827141 10.1684 1.41195 10.1684H9.16337C9.74818 10.1684 10.2222 9.68 10.2222 9.07746V1.09091C10.2222 0.488364 9.74818 0 9.16337 0Z"
                  fill="#2E3192"
                />
                <path
                  d="M9.1635 13.8315H1.41207C0.827268 13.8315 0.353281 14.3199 0.353281 14.9225C0.353281 15.525 0.827268 16.0134 1.41207 16.0134H6.56945L0.333165 22.0606C-0.0928228 22.4737 -0.11294 23.1639 0.287989 23.6025C0.496219 23.8308 0.777504 23.9457 1.05914 23.9457C1.31925 23.9457 1.58042 23.8472 1.78477 23.6494L8.1047 17.521V22.909C8.1047 23.5115 8.57869 23.9999 9.1635 23.9999C9.7483 23.9999 10.2223 23.5115 10.2223 22.909V14.9225C10.2223 14.3199 9.7483 13.8315 9.1635 13.8315Z"
                  fill="#2E3192"
                />
                <path
                  d="M22.2154 0.350546L15.8954 6.47891V1.09091C15.8954 0.488364 15.4214 0 14.8366 0C14.2518 0 13.7778 0.488364 13.7778 1.09091V9.07746C13.7778 9.68 14.2518 10.1684 14.8366 10.1684H22.588C23.1729 10.1684 23.6468 9.68 23.6468 9.07746C23.6468 8.47491 23.1729 7.98655 22.588 7.98655H17.4307L23.667 1.93927C24.0929 1.52618 24.1131 0.836 23.7121 0.397455C23.3108 -0.041818 22.6406 -0.0621816 22.2154 0.350546Z"
                  fill="#2E3192"
                />
                <path
                  d="M17.4307 16.0134H22.588C23.1729 16.0134 23.6468 15.525 23.6468 14.9225C23.6468 14.3199 23.1729 13.8315 22.588 13.8315H14.8366C14.2518 13.8315 13.7778 14.3199 13.7778 14.9225V22.909C13.7778 23.5115 14.2518 23.9999 14.8366 23.9999C15.4214 23.9999 15.8954 23.5115 15.8954 22.909V17.521L22.2154 23.6494C22.4201 23.8475 22.6805 23.9457 22.941 23.9457C23.2226 23.9457 23.5039 23.8308 23.7121 23.6025C24.1131 23.1639 24.0926 22.4737 23.667 22.0606L17.4307 16.0134Z"
                  fill="#2E3192"
                />
              </svg>
    )
}