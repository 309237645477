import React from "react";
import { Col, Input, Row } from "antd";
import { Option } from "./option";
import parse from "html-react-parser";
import useQuizStore from "../../../store/quizStore";
import useMount from "../../../hooks/useMount";
import { onCopy } from "../../../helpers/help";
import { LANG } from "./LanguageConvert";

const { TextArea } = Input;

export default function QuizBody({ language, item }) {
  const { updateVisited, updateQuestion, updateMCQQuestion, status } =
    useQuizStore();

  useMount(() => {
    updateVisited(item.id);
  }, [item.id]);

  return (
    <Col span={24}>
      <p
        onCopy={onCopy}
        className="quiz-questions"
        style={{ fontSize: "1.5em" }}
      >
        {parse(language === LANG.Hi ? item?.hi.q : item?.q)}
      </p>
      <Row>
        <Col
          xl={10}
          lg={12}
          md={15}
          sm={20}
          xs={20}
          style={{ paddingLeft: "1.1em" }}
        >
          {language === LANG.En ? (
            <>
              {item?.type === 4 ? (
                <Input
                  type="number"
                  onChange={(e) => {
                    updateQuestion(item?.id, e.target.value.trim());
                  }}
                  value={status[item?.id].attempt}
                  style={{ marginLeft: "1em" }}
                />
              ) : item?.type === 5 ? (
                <TextArea
                  rows={5}
                  onChange={(e) => {
                    updateQuestion(item?.id, e.target.value);
                  }}
                  style={{ marginLeft: "1em" }}
                />
              ) : item?.type === 3 ? (
                (item?.options || [])?.map((elem, i) => (
                  <Option
                    updateMCQQuestion={updateMCQQuestion}
                    elem={elem}
                    item={item}
                    i={i}
                  />
                ))
              ) : (
                (item?.options || [])?.map((elem, i) => (
                  <Option
                    updateQuestion={updateQuestion}
                    elem={elem}
                    item={item}
                    i={i}
                  />
                ))
              )}
            </>
          ) : (
            <>
              {item?.type === 4 ? (
                <Input
                  onChange={(e) => {
                    updateQuestion(item?.id, e.target.value.trim());
                  }}
                  value={status[item?.id].attempt}
                />
              ) : item?.type === 5 ? (
                <TextArea
                  rows={5}
                  onChange={(e) => {
                    updateQuestion(item?.id, e.target.value);
                  }}
                />
              ) : item?.type === 3 ? (
                (item?.hi?.options || [])?.map((elem, i) => (
                  <Option
                    updateMCQQuestion={updateMCQQuestion}
                    elem={elem}
                    item={item}
                    i={i}
                  />
                ))
              ) : (
                (item?.hi?.options || [])?.map((elem, i) => (
                  <Option
                    updateQuestion={updateQuestion}
                    elem={elem}
                    item={item}
                    i={i}
                  />
                ))
              )}
            </>
          )}
        </Col>
      </Row>
    </Col>
  );
}
