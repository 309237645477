import { Button } from "antd";
import React from "react";
import useQuizStore, { isEqual } from "../../store/quizStore";

const getIndexClass = (status: any, i) => {
  const { answer, attempt, visited } = status;

  if (!`${attempt}` && !visited) return "notVisited";
  if (!`${attempt}`) return "currentQuestion";
  if (isEqual(attempt, answer)) return "question-attempted";
  return "question-incorrect";
};

export function QuestionGraph({ navigate, handleCurrentSection }) {
  const { status, quiz } = useQuizStore();
  return (
    <div
      style={{
        width: "100%",
        background: "#F5F5F5",
        height: "100%",
        padding: "1.2em",
      }}
    >
      <p style={{ color: "#5B5B5B", fontWeight: "800", fontSize: "1.2em" }}>
        Select which Question number you want to see.
      </p>

      <div style={{ height: "90%", overflowY: "scroll" }}>
        {quiz?.is_sectional === 1 ? (
          quiz?.sections?.map((item) => (
            <>
              <p
                style={{
                  color: "#4A4A4A",
                  fontWeight: "700",
                  fontSize: "1.2em",
                  marginBottom: "0.1%",
                }}
              >
                {item?.title}
              </p>
              <hr style={{ border: "1px solid #E5E5E5" }} />

              <div className="quiz-result-list" style={{ marginBottom: "5%" }}>
                {item?.questions?.map((item2, index) => {
                  const currentStatus = status[item2.id];

                  console.log({ currentStatus });
                  return (
                    <Button
                      key={index}
                      onClick={() => {
                        handleCurrentSection(item?.id, index + 1);
                      }}
                      className={`quiz-result-btn ${getIndexClass(
                        currentStatus,
                        index
                      )}`}
                    >
                      {index + 1}
                    </Button>
                  );
                })}
              </div>
            </>
          ))
        ) : (
          <div className="quiz-result-list" style={{ marginBottom: "5%" }}>
            {quiz?.questions?.map((item2, index) => {
              const currentStatus = status[item2.id];
              return (
                <Button
                  key={index}
                  onClick={() => {
                    navigate(currentStatus.position);
                  }}
                  className={`quiz-result-btn ${getIndexClass(
                    currentStatus,
                    index
                  )}`}
                >
                  {index + 1}
                </Button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
