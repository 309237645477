import { Button, Modal, Row, Col, Table, TableProps } from "antd";
import React from "react";
import useQuizStore from "../../store/quizStore";
import { Card } from "antd/lib";
import { useNavigate, useParams } from "@tanstack/react-router";
import api from "../../config/api";
import showNotification from "../../utils/notify";
import { getUserInfo } from "../../helpers/LocalStorageHelper";
import { useAuthStore } from "../../store/auth";
import useMount from "../../hooks/useMount";
import { useTimerStore } from "../../store/timer";

interface IProps {
  isVisible: boolean;
  setVisible: (value: boolean) => void;
  navigateTo: "submit" | "quit";
  isTimeEnd: boolean;
}

interface IColProps {
  key: string;
  section: string;
  que: number;
  answered: number;
  notAnswered: number;
  bookmarked: number;
  notVisited: number;
}

const ReportModal = ({
  isVisible,
  setVisible,
  navigateTo,
  isTimeEnd,
}: IProps) => {
  const { getReport, status, getScore, quiz } = useQuizStore((state) => state);

  const { total, answered, notAnswered, notVisited, bookmarked } = getReport();
  const navigator = useNavigate({ from: "/" });
  const { qId, quizzesType } = useParams({ from: "/" });
  const { timer } = useTimerStore();
  const { user } = useAuthStore();

  const { setActiveTabId } = useQuizStore();

  useMount(() => {
    if (isTimeEnd) {
      handleSubmitQuiz();
    }
  }, [isTimeEnd]);

  const handleSubmitQuiz = async () => {
    if (navigateTo === "quit") {
      return navigator({ to: "/home" });
    }

    const endTimeQuiz = timer;

    const ReduceTime =
      endTimeQuiz?.hours! * 3600 +
      endTimeQuiz?.minutes! * 60 +
      endTimeQuiz?.seconds!;

    const totalTimeSec =
      quiz?.meta?.is_timer && quiz?.meta?.timer?.minutes * 60;

    if (user) {
      const { totalScore, correct, totalMarks, inCorrect } = getScore();
      const quizData = {
        ...user,
        total,
        correct,
        attempt: answered,
        pqw: 1,
        meta: Object.keys(status)?.map((questionId: string) => {
          return { q: questionId, a: status[questionId].attempt };
        }),
        score: totalScore,
        outof: totalMarks,
        incorrect: inCorrect,
        time_duration: +totalTimeSec! - ReduceTime,
      };
      try {
        const { data } = await api.post(`/quizzes/${qId}/leaders`, quizData);
        showNotification("success", "Quiz Submitted Successfully");
      } catch (error) {}
    }
    if (quiz?.is_sectional === 1) setActiveTabId(quiz?.sections[0]?.id);
    window.location.replace(`/quizzes/${quizzesType}/${qId}/result`);
  };

  const styles: any = {
    cards: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    cards_fonts: {
      fontSize: "2.5em",
      textAlign: "center",
      lineHeight: "0",
      marginTop: "20px",
    },
    cards_fonts_title: {
      width: "max-content",
      fontWeight: "bold",
    },
    allQuestions: {
      backgroundColor: "#EBECFF",
      border: "2px solid #2E3192",
      borderRadius: "10px",
      color: "#020887",
    },
    answered: {
      backgroundColor: "#27AE60",
      borderRadius: "10px",
      color: "#fff",
    },
    notAnswered: {
      backgroundColor: "#C0392B",
      borderRadius: "10px",
      color: "#fff",
    },
    reviewed: {
      backgroundColor: "#9B59B6",
      borderRadius: "10px",
      color: "#fff",
    },
    notVisited: {
      backgroundColor: "#fff",
      border: "2px solid #727272",
      borderRadius: "10px",
      color: "#474747",
    },
  };

  const dataSource: IColProps = quiz?.sections?.map((item, index) => ({
    key: index.toString(),
    section: item?.title,
    que: item?.questions?.length,
    answered: item?.questions
      ?.map((que) => que.id)
      .filter((id) => {
        const attempt = status[id]?.attempt;
        if (Array.isArray(attempt)) {
          return attempt.length > 0;
        }
        return attempt !== undefined && attempt !== null && attempt !== 0;
      }).length,
    notAnswered:
      item?.questions?.length -
      item?.questions
        ?.map((que) => que.id)
        .filter((id) => {
          const attempt = status[id]?.attempt;
          if (Array.isArray(attempt)) {
            return attempt.length > 0;
          }
          return attempt !== undefined && attempt !== null && attempt !== 0;
        }).length,
    bookmarked: item?.questions
      ?.map((que) => que.id)
      .filter((id) => status[id]?.bookmark).length,
    notVisited:
      item?.questions?.length -
      item?.questions?.map((que) => que.id).filter((id) => status[id]?.visited)
        .length,
  }));

  const columns = [
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
    },
    {
      title: "No of Que",
      dataIndex: "que",
      key: "que",
    },
    {
      title: "Answered",
      dataIndex: "answered",
      key: "answered",
    },
    {
      title: "Not Answered",
      dataIndex: "notAnswered",
      key: "notAnswered",
    },
    {
      title: "Bookmarked",
      dataIndex: "bookmarked",
      key: "bookmarked",
    },
    {
      title: "Not Visited",
      dataIndex: "notVisited",
      key: "notVisited",
    },
  ];

  return (
    <Modal
      className="modal"
      title="Your Test Report"
      open={isVisible}
      width={800}
      footer
      closeIcon={null}
      onCancel={() => setVisible(false)}
      closable
      style={{ zIndex: "1000" }}
    >
      <Col span={24} className="modal-box" style={{ width: "100%" }}>
        {navigateTo === "quit" && (
          <Row>Are you sure you want to quit the test</Row>
        )}

        {quiz?.is_sectional === 1 ? (
          <Table dataSource={dataSource} columns={columns} pagination={false} />
        ) : (
          <Row
            gutter={[5, 5]}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "20px",
            }}
          >
            <Col md={4} sm={12} xs={24}>
              <Card
                bordered
                style={{ ...styles.cards, ...styles.allQuestions }}
              >
                <p style={styles.cards_fonts_title}>All Questions</p>
                <p style={styles.cards_fonts}>{total}</p>
              </Card>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Card bordered style={{ ...styles.cards, ...styles.answered }}>
                <p style={styles.cards_fonts_title}>Answered</p>
                <p style={styles.cards_fonts}>{answered}</p>
              </Card>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Card bordered style={{ ...styles.cards, ...styles.notAnswered }}>
                <p style={styles.cards_fonts_title}> Not Answered</p>
                <p style={styles.cards_fonts}>{notAnswered}</p>
              </Card>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Card bordered style={{ ...styles.cards, ...styles.reviewed }}>
                <p style={styles.cards_fonts_title}>Bookmarked </p>
                <p style={styles.cards_fonts}>{bookmarked}</p>
              </Card>
            </Col>
            <Col md={4} sm={12} xs={24}>
              <Card bordered style={{ ...styles.cards, ...styles.notVisited }}>
                <p style={styles.cards_fonts_title}>Not Visited</p>
                <p style={styles.cards_fonts}>{notVisited}</p>
              </Card>
            </Col>
          </Row>
        )}

        <Col span={8} style={{ marginTop: "10px" }} />
        <Col span={24} style={{ display: "flex", justifyContent: "end" }}>
          <Button
            size="middle"
            onClick={() => handleSubmitQuiz()}
            className="quiz-start-btn-self btn"
          >
            {navigateTo === "submit" ? "Submit" : "Quit"}
          </Button>
        </Col>
      </Col>
    </Modal>
  );
};

export default ReportModal;
