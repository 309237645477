import { Row, Button, Card, Layout } from "antd";
import React, { useState, useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import QuizFooter from "../../component/quiz/QuizFooter";
import QuizModal from "../../component/quiz/QuizModal";
import QuizBody from "../../component/quiz/attempt/quizBody";
import { Time } from "../../component/quiz/attempt/quizHeader";
import useQuizStore from "../../store/quizStore";
import QuizNameHeader from "./sectionalQuiz/quizHeader/nameHeader";
import QuizSectionHeader from "./sectionalQuiz/quizHeader/sectionHeader";
import QueIndexHeader from "./sectionalQuiz/quizHeader/queIndexHeader";
import Sider from "antd/es/layout/Sider";
import SectionIndex from "./sectionalQuiz/quizIndex/sectionIndex";
import { useMediaQuery } from "react-responsive";
import { LANG } from "../../component/quiz/attempt/LanguageConvert";
import useFullScreen from "../../hooks/useFullScreen";

const { Content } = Layout;

export default function AttemptQuestion() {
  const [drawerOpen, setDrawerOpen] = useState(
    typeof window != "undefined"
      ? window?.screen?.width < 600
        ? false
        : true
      : true
  );
  const [pageId, setPageId] = useState(1);
  const [language, setLanguage] = useState(LANG.En);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [isReportVisible, setReportVisible] = useState<boolean>(false);
  const [navigateTo, setNavigateTo] = useState<"submit" | "quit">("submit");
  const {
    quiz,
    paginate,
    activeTabId,
    setActiveTabId,
    setCurrentSectionQues,
    currentSectionQues,
  } = useQuizStore();
  const { questions, qpp } = paginate(pageId);
  const [isTimeEnd, setIsTimeEnd] = useState(false);

  const [currentSectionTitle, setCurrentSectionTitle] = useState<string>("");
  const [isSectionTimeEnd, setIsSectionTimeEnd] = useState<boolean>(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const onScroll = (index) => {
    const qpp = 1;
    const smallScreen = window?.screen?.width < 600;
    if (smallScreen) {
      setDrawerOpen(!drawerOpen);
    }
    setPageId(index % qpp === 0 ? index / qpp : Math.floor(index / qpp) + 1);
    setTimeout(
      () => {
        const element = document.getElementById(index);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      },
      smallScreen ? 400 : 100
    );
  };

  useEffect(() => {
    if (quiz) {
      setActiveTabId(quiz?.sections?.map((item) => item)[0]?.id);
      setCurrentSectionTitle(quiz?.sections?.map((item) => item)[0]?.title);
      setCurrentSectionQues(quiz?.sections?.map((item) => item)[0]?.questions);
    }
  }, [quiz]);

  const firstSectionId = quiz?.sections?.map((i) => i)[0]?.id;
  const lastSectionId = quiz?.sections?.map((i) => i)?.slice(-1)[0]?.id;

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const isLargeScreen = useMediaQuery({
    query: "(max-width:1030px)",
  });

  useEffect(() => {
    if (isLargeScreen) setCollapsed(!collapsed);
  }, [isLargeScreen]);

  const scalingFactor = window.devicePixelRatio;

  let height = "84%";
  if (quiz?.is_sectional === 1 && isSmallScreen) {
    height = "68%";
  } else if (quiz?.is_sectional === 1 && isSmallScreen === false) {
    height = "76%";
  } else if (isSmallScreen) {
    height = "76%";
  } else if (quiz?.is_sectional === 1) {
    height = "60%";
  }

  const currentSectionMeta = quiz?.sections?.filter(
    (item) => item?.id === activeTabId
  )?.[0]?.meta;

  const initTime =
    (currentSectionMeta?.is_timer && currentSectionMeta?.timer) ||
    (quiz?.meta.is_timer && quiz?.meta?.timer);

  useEffect(() => {
    if (isSectionTimeEnd) {
        setPageId(()=>1);
      setActiveTabId(activeTabId + 1);
      setCurrentSectionQues(
        quiz?.sections?.find((i) => i?.id === activeTabId + 1)?.questions
      );
      setCurrentSectionTitle(
        quiz?.sections?.find((i) => i?.id === activeTabId + 1)?.title
      );
      setIsSectionTimeEnd(false);
    }
  }, [isSectionTimeEnd]);


  useEffect(() => {
    if (lastSectionId < activeTabId) {
      setIsTimeEnd(true);
      setNavigateTo("submit");
    }
  }, [lastSectionId, activeTabId]);

  const { enterFullscreen, exitFullscreen, isFullscreen } = useFullScreen();

  return (
    <Row className="bg-image" align={"middle"}>
      <Layout
        style={{
          margin: isSmallScreen ? "0rem" : "1rem",
          height: isSmallScreen ? "100vh" : "96vh",
          borderRadius: "5em",
          background: "white",
        }}
      >
        <Layout style={{ borderRadius: "0.8em" }}>
          <Content
            style={{
              height: "100%",
            }}
          >
            <div
              style={{
                background: "#fff",
                height: "100%",
                borderRadius: "0.8em",
              }}
            >
              {/* This is header */}
              <div style={{ height: "8%" }}>
                <QuizNameHeader
                  quiz={quiz}
                  onQuit={() => {
                    setNavigateTo("quit");
                    setReportVisible(true);
                  }}
                  onCompleteTimer={() => {
                    setIsTimeEnd(true);
                    setNavigateTo("submit");
                  }}
                  onSectionCompleteTimer={() => {
                    setIsSectionTimeEnd(true);
                    setPageId(()=>1);
                  }}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  scalingFactor={scalingFactor}
                  activeTabId={activeTabId}
                  setActiveTabId={setActiveTabId}
                  enter={enterFullscreen}
                  exit={exitFullscreen}
                  fullscreen={isFullscreen}
                  isDrawerOpen={isDrawerOpen}
                  setIsDrawerOpen={setIsDrawerOpen}
                  handleScroll={onScroll}
                  pageId={pageId}
                  title={currentSectionTitle}
                  isSmallScreen={isSmallScreen}
                  lastSectionId={lastSectionId}
                />
              </div>

              {/* This is sectional header */}
              {quiz?.is_sectional === 1 && (
                <div style={{ height: "8%" }}>
                  <QuizSectionHeader
                    quiz={quiz}
                    setActiveTab={setActiveTabId}
                    setCurrentSectionQues={setCurrentSectionQues}
                    onScroll={onScroll}
                    setCurrentSectionTitle={setCurrentSectionTitle}
                    setLanguage={setLanguage}
                    language={language}
                    scalingFactor={scalingFactor}
                  />
                </div>
              )}

              {/* This is Time Left & Quit Button for small screen */}
              {isSmallScreen && (
                <div
                  style={{
                    height: "8%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: "2em",
                    paddingRight: "2em",
                  }}
                >
                  {initTime && (
                    <Time
                      handleComplete={() => {
                        if (quiz?.meta?.is_timer) {
                          setIsTimeEnd(true);
                          setNavigateTo("submit");
                        } else {
                          setIsSectionTimeEnd(true);
                        }
                      }}
                      initialTime={initTime}
                      isSmallScreen={isSmallScreen}
                    />
                  )}
                  <Button
                    style={{ fontWeight: "bold", color: "#2E1991" }}
                    onClick={() => {
                      setNavigateTo("quit");
                      setReportVisible(true);
                    }}
                    size={isSmallScreen ? "middle" : "large"}
                  >
                    Quit Test
                  </Button>
                </div>
              )}

              {/* This is Que & Option container */}
              <div
                style={{
                  height: height,
                }}
              >
                {questions?.map((item) => (
                  <>
                    <div style={{ height: "8%" }}>
                      <QueIndexHeader
                        item={item}
                        pageId={pageId}
                        setLanguage={setLanguage}
                        language={language}
                        is_sectional={quiz?.is_sectional}
                        isSmallScreen={isSmallScreen}
                      />
                    </div>

                    <Row
                      style={{
                        height: "90%",
                        marginBottom: "1%",
                        marginTop: "1%",
                        overflowY: "scroll",
                        paddingTop: "1em",
                      }}
                    >
                      <QuizBody language={language} item={item} />
                    </Row>
                  </>
                ))}
              </div>

              {/* This is footer */}
              <div style={{ height: "8%" }}>
                <QuizFooter
                  currentSectionMeta={currentSectionMeta}
                  scalingFactor={scalingFactor}
                  pageId={pageId}
                  paginate={(id: number) => setPageId(id)}
                  length={
                    (quiz?.questions || currentSectionQues)?.length! % qpp === 0
                      ? Math.floor(
                          (quiz?.questions || currentSectionQues)?.length! / qpp
                        )
                      : Math.floor(
                          (quiz?.questions || currentSectionQues)?.length! / qpp
                        ) + 1
                  }
                  prevSection={
                    firstSectionId !== activeTabId &&
                    currentSectionQues?.[0]?.id === questions?.[0]?.id && (
                      <Button
                        style={{
                          fontWeight: 800,
                          paddingLeft: "1.5em",
                          paddingRight: "1.5em",
                        }}
                        onClick={() => {
                          setActiveTabId(activeTabId - 1);
                          setCurrentSectionQues(
                            quiz?.sections?.find(
                              (i) => i?.id === activeTabId - 1
                            )?.questions
                          );
                          setCurrentSectionTitle(
                            quiz?.sections?.find(
                              (i) => i?.id === activeTabId - 1
                            )?.title
                          );
                          setPageId(1);
                        }}
                        size={scalingFactor >= 1.5 ? "middle" : "large"}
                      >
                        Previous
                      </Button>
                    )
                  }
                  nextSection={
                    lastSectionId !== activeTabId &&
                    currentSectionQues?.[currentSectionQues?.length - 1]?.id ===
                      questions?.[0]?.id && (
                      <Button
                        onClick={() => {
                          setActiveTabId(activeTabId + 1);
                          setCurrentSectionQues(
                            quiz?.sections?.find(
                              (i) => i?.id === activeTabId + 1
                            )?.questions
                          );
                          setCurrentSectionTitle(
                            quiz?.sections?.find(
                              (i) => i?.id === activeTabId + 1
                            )?.title
                          );
                          setPageId(1);
                        }}
                        className="quiz-start-btn-self"
                        style={{
                          fontWeight: 800,
                          paddingLeft: "2em",
                          paddingRight: "2em",
                        }}
                        size={scalingFactor >= 1.5 ? "middle" : "large"}
                      >
                        Save & Next
                      </Button>
                    )
                  }
                />
              </div>
            </div>
          </Content>
          <Sider
            width={!isSmallScreen ? "30em" : "0%"}
            theme="light"
            collapsedWidth={0}
            reverseArrow
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => {
              setCollapsed(value);
            }}
            trigger={null}
            style={{
              background: "#D5D5D5",
              borderRadius: "0em 0.8em 0.8em 0em",
              padding: !collapsed ? "1em" : "0em",
            }}
          >
            <Card
              style={{ height: "100%" }}
              bodyStyle={{ padding: "0%" }}
            >
              <div
                style={{
                  position: "absolute",
                  right: "1.8%",
                  top: "1%",
                  cursor: "pointer",
                  fontSize: "1.3em",
                }}
                onClick={() => setCollapsed(!collapsed)}
              >
                <CloseOutlined />
              </div>

              <div
              style={{
                height: isSmallScreen ? "97vh" : "93vh",
              }}
              >
                <SectionIndex
                  scalingFactor={scalingFactor}
                  handleScroll={onScroll}
                  pageId={pageId}
                  title={currentSectionTitle}
                  onClickSubmit={() => {
                    setNavigateTo("submit");
                    setReportVisible(true);
                  }}
                  isSmallScreen={isSmallScreen}
                  lastSectionId={lastSectionId}
                  setCurrentSectionTitle={setCurrentSectionTitle}
                  setPageId={setPageId}
                />
              </div>
            </Card>
          </Sider>
        </Layout>
      </Layout>
      <QuizModal
        isVisible={isReportVisible}
        setVisible={setReportVisible}
        navigateTo={navigateTo}
        isTimeEnd={isTimeEnd}
      />
    </Row>
  );
}
