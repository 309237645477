export default function CorrectIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0C7.16429 0 0 7.16429 0 16C0 24.8357 7.16429 32 16 32C24.8357 32 32 24.8357 32 16C32 7.16429 24.8357 0 16 0ZM22.9107 10.775L15.3893 21.2036C15.2842 21.3503 15.1456 21.4699 14.985 21.5523C14.8245 21.6348 14.6466 21.6778 14.4661 21.6778C14.2856 21.6778 14.1077 21.6348 13.9471 21.5523C13.7866 21.4699 13.648 21.3503 13.5429 21.2036L9.08929 15.0321C8.95357 14.8429 9.08929 14.5786 9.32143 14.5786H10.9964C11.3607 14.5786 11.7071 14.7536 11.9214 15.0536L14.4643 18.5821L20.0786 10.7964C20.2929 10.5 20.6357 10.3214 21.0036 10.3214H22.6786C22.9107 10.3214 23.0464 10.5857 22.9107 10.775Z"
        fill="#27AE60"
      />
    </svg>
  );
}
