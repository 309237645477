import { create } from "zustand";
import { persist } from "zustand/middleware";

interface IAdmitCardStoreProps {
  popUpID: number[];
  setPopUpId: (val: number[]) => void;
  lastApiHit: Date | null;
  setLastApiHit: (date: Date) => void;
}

export const admitCardStore = create(
  persist<IAdmitCardStoreProps>(
    (set) => ({
      popUpID: [],
      setPopUpId: (value) =>
        set((state) => {
          const isExist = state.popUpID.some((activity) => activity === value);

          if (!isExist) {
            let updatedActivity = [...state.popUpID, value];

            return { ...state, popUpID: updatedActivity };
          }

          return state;
        }),
      lastApiHit: null,
      setLastApiHit: (date) => set({ lastApiHit: date }),
    }),
    {
      name: "admitCardStore",
    }
  )
);
