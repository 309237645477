import React, { useEffect, useState } from "react";
import { Row, Col, Tabs } from "antd";
import YourResult from "../../component/quiz/result/yourResult";
import TopLeader from "../../component/quiz/result/topLeader";
import ReviewAndExplanation from "../../component/quiz/result/reviewAndExplanation";
import Header from "../../component/quiz/result/header";
import useApi from "../../hooks/useApi";
import { useParams, useRouter } from "@tanstack/react-router";
import useMount from "../../hooks/useMount";
import { useAuthStore } from "../../store/auth";
import useQuizStore from "../../store/quizStore";

const QuizResult = () => {
  const [currentSectionTitle, setCurrentSectionTitle] = useState<string>("");
  const [pageId, setPageId] = useState(1);

  const { user } = useAuthStore();

  const { qId } = useParams({ from: "quizzes/$quizzesType/$qId/start" });
  const { data, load } = useApi(`quizzes/${qId}/leaders?phone=${user?.phone}`);

  const {
    data: userRank,
    isLoading: userLoading,
    load: userLoad,
  } = useApi(
    `/user/${qId}/quiz-rank?with_submission=true&phone=${user?.phone}`
  );

  useMount(() => {
    load();
    userLoad();
  });

  const items = [
    {
      key: "1",
      label: "Your Result",
      children: <YourResult />,
    },
    {
      key: "2",
      label: "Top 10 Leader",
      children: (
        <TopLeader data={data} userRank={userRank?.submission! && userRank} />
      ),
    },
    {
      key: "3",
      label: "Review and Explanation",
      children: (
        <ReviewAndExplanation
          currentSectionTitle={currentSectionTitle}
          setCurrentSectionTitle={setCurrentSectionTitle}
          pageId={pageId}
          setPageId={setPageId}
        />
      ),
    },
  ];

  const [activeKey, setActiveKey] = useState("1");

  const handleTabChange = (key) => {
    setActiveKey(key);
  };

  const { quiz, setActiveTabId, setCurrentSectionQues } = useQuizStore();

  const handleNavigation = (position: number) => {
    setPageId(position);
    const element = document.getElementById("explanation");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleCurrentSection = (id: number, index: number) => {
    const currSecQues = quiz?.sections?.find(
      (item) => item?.id === id
    )?.questions;
    const title = quiz?.sections?.find((item) => item?.id === id)?.title;
    setCurrentSectionTitle(title);
    setCurrentSectionQues(currSecQues);
    handleNavigation(index);
    setActiveTabId(id);
  };

  return (
    <Row
      justify="center"
      align="middle"
      style={{ height: "100%" }}
      className="quizSVG"
    >
      <Col
        span={23}
        style={{
          height: "95vh",
          borderRadius: "0.5em",
        }}
      >
        <Row style={{ height: "10%" }}>
          <Header
            activeKey={activeKey}
            setCurrentSectionTitle={setCurrentSectionTitle}
            pageId={pageId}
            setPageId={setPageId}
            handleCurrentSection={handleCurrentSection}
            handleNavigation={handleNavigation}
          />
        </Row>
        <Row
          style={{
            height: "90%",
            background: "white",
            paddingTop: "1%",
            borderRadius: "0em 0em 0.5em 0.5em",
          }}
        >
          <Col
            xxl={+activeKey === 3 ? 22 : 19}
            xl={+activeKey === 3 ? 22 : 19}
            lg={+activeKey === 3 ? 22 : 19}
            md={+activeKey === 3 ? 22 : 19}
            sm={+activeKey === 3 ? 22 : 22}
            xs={+activeKey === 3 ? 22 : 22}
            style={{ marginLeft: "4%" }}
          >
            <Tabs
              items={items}
              defaultActiveKey="1"
              activeKey={activeKey}
              onChange={handleTabChange}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default QuizResult;
