import React from "react";
import { Row, Col, Card, Tooltip } from "antd";
import { Pie } from "react-chartjs-2";
import CorrectIcon from "../../icons/result/CorrectIcon";
import IncorrectIcon from "../../icons/result/IncorrectIcon";
import AccuracyIcon from "../../icons/result/AccuracyIcon";
import ScoreIcon from "../../icons/result/ScoreIcon";
import ScoreInfo from "./scoreInfo";
import useQuizStore from "../../../store/quizStore";
import styled from "styled-components";
import { useRouter } from "@tanstack/react-router";
import { useMediaQuery } from "react-responsive";

const YourResult = () => {
  const router = useRouter();
  const getScore = useQuizStore((state) => state.getScore);
  const score = getScore();
  const data = {
    labels: ["Correct", "Incorrect", "Visited", "Not Visited"],
    datasets: [
      {
        data: [score.correct, score.inCorrect, score.visited, score.notVisited],
        backgroundColor: ["#27AE60", "#ef4444", "#C0392B", "#FFF"],
        borderWidth: 0.5,
        borderColor:"#DADADA",
      },
    ],
  };

  const attempt = score.correct + score.inCorrect;

  const accuracy = (score.correct * 100) / attempt;

  const resultData = [
    {
      title: "Correct",
      icon: <CorrectIcon />,
      value: score.correct,
    },
    {
      title: "Incorrect",
      icon: <IncorrectIcon />,
      value: score.inCorrect,
    },
    {
      title: "Score",
      icon: <ScoreIcon />,
      value: score.totalScore,
    },
    {
      title: "Accuracy",
      icon: <AccuracyIcon />,
      value: accuracy ? accuracy.toFixed(2) + "%" : "0 %",
    },
  ];

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <Container>
      <Title>
        {!isMobile && (
          <span
            style={{ marginRight: "1%", cursor: "pointer" }}
            onClick={() => router.history.go(-2)}
            id="hide-element"
          >
            <Tooltip title="Exit" arrow={false} placement="top">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.41 10.4064L2.83 5.99999L7.41 1.59359L6 0.23999L0 5.99999L6 11.76L7.41 10.4064Z"
                  fill="#202224"
                />
              </svg>
            </Tooltip>
          </span>
        )}
        Your Result
      </Title>
      <SubContainer>
        <Row gutter={24} align="middle" justify="center">
          <Col xs={24} sm={24} md={16} lg={8} xl={8}>
            <Card title="Result" bordered={false}>
              <Pie
                data={data}
                options={{
                  plugins: {
                    legend: {
                      position: "right",
                      labels: {
                        padding: 15,
                        usePointStyle: true,
                        font: {
                          size: 10,
                        },
                      },
                    },
                  },
                  maintainAspectRatio: false,
                }}
              />
            </Card>
          </Col>
          {resultData.map((item) => (
            <ScoreInfo title={item.title} icon={item.icon} score={item.value} />
          ))}
        </Row>
      </SubContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 0.5em;
`;

const SubContainer = styled.div`
  overflow-y: scroll;
  height: 62vh;
  padding: 2%;
`;

const Title = styled.p`
  font-weight: 600;
  background: #eaf0ff;
  padding: 1% 1% 1% 2%;
  border-radius: 0.3em 0.3em 0em 0em;
  margin-bottom: 0%;
  font-size: 1.2em;
`;

export default YourResult;
