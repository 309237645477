import { Button, Row, Col } from "antd";
import React from "react";

const QuizFooter = ({
  pageId,
  paginate,
  length,
  prevSection,
  nextSection,
  scalingFactor,
  currentSectionMeta,
}) => {
  return (
    <Row
      align="middle"
      style={{
        height: "100%",
        width: "100%",
        paddingLeft: "2em",
        paddingRight: "1em",
      }}
      justify={"space-between"}
    >
      <Col>
        {pageId > 1 && (
          <Button
            style={{
              fontWeight: 800,
              paddingLeft: "1.5em",
              paddingRight: "1.5em",
            }}
            className={pageId.length === 0 ? "text" : ""}
            onClick={() => paginate(pageId - 1)}
            size={scalingFactor >= 1.5 ? "middle" : "large"}
          >
            Previous
          </Button>
        )}
        {prevSection && !currentSectionMeta?.is_timer && prevSection}
      </Col>

      <Col>
        {pageId < length && (
          <Button
            style={{
              fontWeight: 800,
              paddingLeft: "2em",
              paddingRight: "2em",
            }}
            className="quiz-start-btn-self"
            onClick={() => paginate(pageId + 1)}
            size={scalingFactor >= 1.5 ? "middle" : "large"}
          >
            Save & Next
          </Button>
        )}
        {nextSection && !currentSectionMeta?.is_timer && nextSection}
      </Col>
    </Row>
  );
};

export default QuizFooter;
