import { Button, Form, Input } from "antd";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { useAuthStore } from "../../store/auth";
import showNotification from "../../utils/notify";
import api from "../../config/api";
import { admitCardStore } from "../../store/admitCardStore";

const CourseAdmitCard = ({ courseDetails, setIsModalOpen }) => {
  const [attachment, setAttachment] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const { user } = useAuthStore();
  const [form] = Form.useForm();

  const { lastApiHit, setPopUpId, setLastApiHit } = admitCardStore();

  useEffect(() => {
    form.setFieldsValue({
      name: user?.name,
      email: user?.email,
      phone: user?.phone,
      course_id: courseDetails?.id,
      course_name: courseDetails?.title,
    });
  }, [user, courseDetails]);

  function onChange(e) {
    e.preventDefault();

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      if (file.size > 2621440) {
        showNotification("error", "The file uploaded is more than 2.5 MB", 3);
        e.target.form.reset();
        setAttachment(null);
      } else {
        setAttachment(file);
        showNotification("success", "The file is ready to be uploaded", 3);
      }
    } else {
      e.target.form.reset();
      setAttachment(null);
    }
  }

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const res = await api.post(
        `https://admin.khanglobalstudies.com/api/admitcards`,
        {
          course_id: values.course_id,
          file: attachment,
          rollno: values.rollno,
        },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (res?.status === 200) {
        showNotification("success", `Successfully Submitted`, 3);
        setLastApiHit(new Date());
        setPopUpId(values.course_id);
        setIsModalOpen(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <h2 style={{ color: "red", fontWeight: "600", textAlign: "center" }}>
        Kindly upload your Admit Card
      </h2>

      <Form
        layout="vertical"
        name="update-profile"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item name="name" label={"Name"}>
          <Input
            defaultValue={user?.name}
            disabled={true}
            placeholder="Name"
            type="text"
            size="large"
          />
        </Form.Item>

        <Form.Item name="email" label={"Email Address"}>
          <Input
            defaultValue={user?.email}
            disabled={true}
            placeholder="Email"
            type="text"
            size="large"
          />
        </Form.Item>

        <Form.Item name="phone" label={"Phone Number"}>
          <Input
            defaultValue={user?.phone}
            placeholder="Phone"
            disabled={true}
            onInput={(e: BaseSyntheticEvent) =>
              (e.target.value = e.target.value.slice(0, 10))
            }
            type="number"
            size="large"
          />
        </Form.Item>

        {/* course id */}
        <Form.Item name="course_id" label={"Course Id"} hidden={true}>
          <Input
            defaultValue={courseDetails?.id}
            disabled={true}
            placeholder="Course"
            type="text"
            size="large"
          />
        </Form.Item>

        {/* course Name */}
        <Form.Item name="course_name" label={"Course Name"}>
          <Input
            defaultValue={courseDetails?.title}
            disabled={true}
            placeholder="Course"
            type="text"
            size="large"
          />
        </Form.Item>

        {/* roll no */}

        <Form.Item
          name="rollno"
          label={"Roll No"}
          rules={[
            {
              required: true,
              message: "Please enter the Roll No",
            },
          ]}
        >
          <Input type="number" placeholder="Roll No" size="large" />
        </Form.Item>

        <Form.Item
          name="file"
          label="Upload Admit Card"
          rules={[{ required: true, message: "Please upload your admit card" }]}
        >
          <input
            type="file"
            onChange={onChange}
            id="attachment"
            style={{ fontSize: "15px" }}
            accept=".pdf"
          />
        </Form.Item>

        <Form.Item>
          <Button
            loading={loading}
            disabled={loading}
            type="primary"
            htmlType="submit"
            size="large"
            className="w-100"
          >
            Upload
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default CourseAdmitCard;
