import React, { useEffect, useRef, useState } from "react";
import { Modal, Tabs } from "antd";
import AppMetaHeader from "../../component/AppMetaHeader";
import PageContainer from "../../component/Layout/PageContainer";
import { useApi, useMount } from "../../hooks";
import AllCourse from "./AllCourse";
import RecentlyAdded from "./RecentlyAdded";
import ExpiringCourse from "./ExpiringCourse";
import MyOngoing from "./MyOngoing";
import CourseAdmitCard from "./CourseAdmitCard";
import { admitCardStore } from "../../store/admitCardStore";

interface IMyCourse {
  id: number;
  slug: string;
  title: string;
  image: {
    small: string;
    medium: string;
  };
  is_recent: boolean;
  is_expire_soon: boolean;
}
function CourseListing() {
  const [activeKey, setActiveKey] = useState("1");
  const [isClicked, setIsClicked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, isLoading, load } = useApi<IMyCourse[]>("user/v2/courses");

  const {
    data: publicData,
    isLoading: publicLoading,
    load: publicLoad,
  } = useApi(
    `https://admin.khanglobalstudies.com/api/public?medium=1&no_cache=1`
  );

  const { popUpID, lastApiHit, setLastApiHit, setPopUpId } = admitCardStore();

  const [
    allCourse,
    recentlyAdded,
    expiringCourse,
    ongoingCourse,
  ]: IMyCourse[][][][] = [[], [], [], []];

  useMount(() => {
    load();
    publicLoad();
  });

  const admitCardPopUp = publicData?.configs?.find(
    (i) => i?.name === "APP_ADMITCARD_POPUP"
  )?.value;

  useEffect(() => {
    document.title = "Courses | Khan Global Studies";
  }, []);

  const onChange = (key: string) => {
    setActiveKey(key);
    setIsClicked(true);
  };

  data?.forEach((datum) => {
    allCourse.push(datum);
    datum.is_recent && recentlyAdded.push(datum);
    datum.is_expire_soon && expiringCourse.push(datum);
    if (!datum.is_recent && !datum.is_expire_soon) {
      ongoingCourse.push(datum);
    }
  });

  const allCoursesId = allCourse?.map((i) => i?.id);
  const allAdmitCardid = admitCardPopUp?.split(",").map((item) => +item.trim());

  const admitCardIds = allCoursesId?.filter((val) =>
    allAdmitCardid?.includes(val)
  );

  const unmatchedIds = admitCardIds.filter((id) => !popUpID.includes(id));
  const courseDetails = allCourse?.find((i) => i?.id === unmatchedIds[0]);

  const {
    data: admitCardData,
    load: admitCardLoad,
    isLoading: admitCardLoading,
  } = useApi(`https://admin.khanglobalstudies.com/api/check-admitcard`);

  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

  const hasCalledApi = useRef(false);

  useMount(() => {
    const isTimeLapsed =
      new Date().getTime() - new Date(lastApiHit!).getTime() >
      oneDayInMilliseconds;

    if (!hasCalledApi.current && unmatchedIds.length > 0 && isTimeLapsed) {
      admitCardLoad({ course_id: unmatchedIds[0] });
      hasCalledApi.current = true;
    }
  }, [unmatchedIds, lastApiHit]);

  useEffect(() => {
    if (!admitCardData) return;
    if (admitCardData?.status === "not_filled") setIsModalOpen(true);
    if (admitCardData?.status === "already_filled") setPopUpId(unmatchedIds[0]);
  }, [admitCardData]);

  const items = [
    {
      key: "1",
      label: (
        <span className={activeKey === "1" ? (isClicked ? "tabs_1" : "") : ""}>
          All
        </span>
      ),
      children: <AllCourse loading={isLoading} data={allCourse} />,
    },
    {
      key: "2",
      label: (
        <span className={activeKey === "2" ? (isClicked ? "tabs_1" : "") : ""}>
          My Ongoing
        </span>
      ),
      children: <MyOngoing loading={isLoading} data={ongoingCourse} />,
    },
    {
      key: "3",
      label: (
        <span
          className={
            activeKey === "3" ? (!isClicked ? "tabs_1" : "tabs_1") : ""
          }
        >
          Recently Added
        </span>
      ),
      children: <RecentlyAdded loading={isLoading} data={recentlyAdded} />,
    },

    {
      key: "4",
      label: (
        <span className={activeKey === "4" ? (isClicked ? "tabs_1" : "") : ""}>
          Expiring Soon
        </span>
      ),
      children: <ExpiringCourse data={expiringCourse} />,
    },
  ];

  // const getCurrentPageTitle = () => {
  //   const activeItem = items.find((item) => item.key === activeKey);
  //   return `${activeItem ? activeItem.label.props.children : ""}`;
  // };

  return (
    <>
      <AppMetaHeader currentPageTitle="My Courses" />
      <PageContainer
        currentPageTitle={"All Courses"}
        // title={"Courses"}
        // // loading={isLoading}
        // url="/Courses"
        transparent={true}
      >
        <Tabs
          tabBarStyle={{
            width: "100%",
            paddingBottom: "0.5%",
            position: "fixed",
            zIndex: "1",
            backgroundColor: "#EDF4FF",
          }}
          defaultActiveKey="1"
          items={items}
          activeKey={activeKey}
          onChange={onChange}
          size="small"
        />

        <Modal
          open={isModalOpen}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          closeIcon={false}
          centered
        >
          <CourseAdmitCard
            courseDetails={courseDetails}
            setIsModalOpen={setIsModalOpen}
          />
        </Modal>
      </PageContainer>
    </>
  );
}

export default CourseListing;
