import { Tag } from "antd";
import React from "react";
import useQuizStore from "../../../../store/quizStore";

const QuizCounts = () => {
  const { quiz, status, currentSectionQues } = useQuizStore();

  const currentIds = currentSectionQues?.map((question) => question.id.toString());

  const filteredStatus = Object.fromEntries(
    Object.entries(status)?.filter(([key]) => currentIds?.includes(key))
  );

  const visitedCount = Object.values(filteredStatus)?.filter(
    (item) => item.visited
  ).length;

  const bookmarkedCount = Object.values(filteredStatus)?.filter(
    (item) => item?.bookmark
  ).length;

  const attemptedCount = Object.values(filteredStatus)?.filter((item) =>
    Array.isArray(item.attempt) ? item.attempt.length > 0 : !!item.attempt
  ).length;

  const nonSecAttmept = Object.values(status)?.filter((item) =>
    Array.isArray(item.attempt) ? item.attempt.length > 0 : !!item.attempt
  ).length;

  const nonSecBookmarkedCount = Object.values(status)?.filter(
    (item) => item?.bookmark
  ).length;

  const nonSecVisitedCount = Object.values(status)?.filter(
    (item) => item.visited
  ).length;

  const tagStyle: React.CSSProperties = {
    paddingTop: "0.3em",
    paddingBottom: "0.3em",
    paddingLeft: "1em",
    paddingRight: "1em",
    margin: "0.5em",
    fontSize: "1.1em",
    borderRadius: "0.6em",
    userSelect: "none",
  };

  const totalQuestions = quiz?.is_sectional === 1 ? currentSectionQues : quiz?.questions;
  const totalNotVisited = totalQuestions?.length - (visitedCount || nonSecVisitedCount);
  const totalNotAnswered = totalQuestions?.length - (quiz?.is_sectional === 1 ? attemptedCount : nonSecAttmept);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "18%",
      }}
    >
      <Tag style={tagStyle} className="question-attempted">
        {quiz?.is_sectional === 1 ? attemptedCount : nonSecAttmept} Attempted
      </Tag>

      <Tag style={tagStyle} className="question-bookmarked">
        {bookmarkedCount || nonSecBookmarkedCount} Bookmarked
      </Tag>

      <Tag style={tagStyle} className="question-default">
        {totalNotVisited} Not Visited
      </Tag>

      <Tag style={tagStyle} className="currentQuestion">
        {totalNotAnswered} Not Answered
      </Tag>
    </div>
  );
};

export default QuizCounts;
