import { Button, Drawer, Row } from "antd";
import React from "react";
import { Time } from "../../../../component/quiz/attempt/quizHeader";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import EnterFullScreenIcon from "../../../../component/icons/FullScreenIcon";
import ExitFullScreenIcon from "../../../../component/icons/ExitFullScreenIcon";
import SectionIndex from "../quizIndex/sectionIndex";

const QuizNameHeader = ({
  quiz,
  onQuit,
  onCompleteTimer,
  collapsed,
  setCollapsed,
  scalingFactor,
  activeTabId,
  setActiveTabId,
  onSectionCompleteTimer,
  enter,
  exit,
  fullscreen,
  isDrawerOpen,
  setIsDrawerOpen,
  handleScroll,
  title,
  pageId,
  lastSectionId,
}: any) => {
  const isSmallScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const currentSection = quiz?.sections?.filter(
    (item) => item?.id === activeTabId
  )?.[0]?.meta;

  const initTime =
    (currentSection?.is_timer && currentSection?.timer) ||
    (quiz?.meta.is_timer && quiz?.meta?.timer);

  return (
    <>
      <Row
        align={"middle"}
        style={{
          height: "100%",
          background: "white",
          boxShadow: "0px 4px 4px 0px #00000040",
          flexFlow: "nowrap",
          borderRadius: "0.8em 0.8em 0em 0em",
          paddingLeft: "2em",
          paddingRight: "2em",
        }}
        justify={"space-between"}
      >
        {/* Title */}
        <p
          style={{
            fontSize: "1.4em",
            fontWeight: "bold",
            margin: "0%",
          }}
          className="card-content"
        >
          {quiz?.title}
        </p>
        <div
          style={{
            gap: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* Timer Button */}
          {!isSmallScreen && initTime && (
            <Time
              handleComplete={
                quiz?.meta?.is_timer ? onCompleteTimer : onSectionCompleteTimer
              }
              initialTime={initTime}
              scalingFactor={scalingFactor}
            />
          )}

          {/* Quit Button */}
          {!isSmallScreen && (
            <Button
              style={{ fontWeight: "bold", color: "#2E1991" }}
              onClick={() => onQuit()}
              size={scalingFactor >= 1.5 ? "middle" : "large"}
            >
              Quit Test
            </Button>
          )}

          {/* Full Screen & Exit Screen */}
          {!isSmallScreen && (
            <Button
              style={{
                fontWeight: "bold",
                color: "#2E1991",
                gap: "5px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                fullscreen ? exit() : enter();
              }}
              size={scalingFactor >= 1.5 ? "middle" : "large"}
              icon={
                fullscreen ? <ExitFullScreenIcon /> : <EnterFullScreenIcon />
              }
            >
              {fullscreen ? "Exit" : "Full Screen"}
            </Button>
          )}

          {/* menu button */}
          {collapsed && (
            <MenuOutlined
              style={{
                color: "#161976",
                fontSize: "2em",
                margin: "0.5%",
              }}
              onClick={() => {
                if (isSmallScreen) {
                  setIsDrawerOpen(true);
                } else setCollapsed(!collapsed);
              }}
            />
          )}
        </div>
      </Row>

      <Drawer
        closable={false}
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
        bodyStyle={{ padding: "1%" }}
      >
        <div
          style={{
            position: "absolute",
            right: "1.8%",
            top: "1%",
            cursor: "pointer",
            fontSize: "1.3em",
          }}
          onClick={() => setIsDrawerOpen(false)}
        >
          <CloseOutlined />
        </div>
        <SectionIndex
          onClickSubmit={onCompleteTimer}
          handleScroll={handleScroll}
          title={title}
          pageId={pageId}
          scalingFactor={scalingFactor}
          lastSectionId={lastSectionId}
        />
      </Drawer>
    </>
  );
};

export default QuizNameHeader;
