import { Divider, Drawer } from "antd";
import React from "react";
import useQuizStore from "../../../store/quizStore";
import parse from "html-react-parser";
import { CloseOutlined } from "@ant-design/icons";

interface IProps {
  isQueDrawerOpen: boolean;
  setIsQueDrawerOpen: (val: boolean) => void;
}

const QuePaper = ({ isQueDrawerOpen, setIsQueDrawerOpen }: IProps) => {
  const { quiz, currentSectionQues } = useQuizStore();

  return (
    <>
      <Drawer
        closable={false}
        onClose={() => setIsQueDrawerOpen(false)}
        open={isQueDrawerOpen}
        title="Question Paper"
      >
        {/* for right side close icon  */}
        <CloseOutlined
          onClick={() => setIsQueDrawerOpen(false)}
          style={{
            position: "absolute",
            right: "1.8%",
            top: "1%",
            cursor: "pointer",
            fontSize: "1.3em",
          }}
        />

        {/* for sectional but sectional timer based continue*/}
        {quiz?.meta.is_section_timer === true &&
          quiz!.is_sectional === 1 &&
          currentSectionQues?.map((ques, index) => (
            <>
              <span style={{ fontWeight: "bold" }}>{`Que.${index + 1}`}</span>
              <p className="quePaper">{parse(ques?.q)}</p>
              <Divider />
            </>
          ))}

        {/* for sectional */}
        {quiz!.is_sectional === 1 &&
          quiz?.meta.is_section_timer === (false || undefined) &&
          quiz!.sections.map((item) => (
            <>
              <p style={{ fontWeight: "bold", fontSize: "1.1em" }}>
                {item?.title}
              </p>
              {item.questions.map((ques, index) => (
                <>
                  <span style={{ fontWeight: "bold" }}>{`Que.${
                    index + 1
                  }`}</span>
                  <p className="quePaper">{parse(ques?.q)}</p>
                  <Divider />
                </>
              ))}
            </>
          ))}

        {/* for non sectional */}
        {quiz!.is_sectional === 0 &&
          quiz!.questions!.map((ques, index) => (
            <>
              <span style={{ fontWeight: "bold" }}>{`Que.${index + 1}`}</span>
              <p className="quePaper">{parse(ques?.q)}</p>
              <Divider />
            </>
          ))}
      </Drawer>
    </>
  );
};

export default QuePaper;
