export default function EnterFullScreenIcon(){
    return (
        <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 15.386C0 15.4259 0.00306953 15.4658 0.012279 15.5057C0.0153489 15.5241 0.0214889 15.5425 0.0276286 15.5579C0.0337683 15.5794 0.0399078 15.5978 0.0460475 15.6193C0.055257 15.6408 0.0675364 15.6592 0.076746 15.6807C0.0859555 15.696 0.0920951 15.7114 0.101305 15.7267C0.147352 15.7943 0.205679 15.8526 0.270146 15.8956C0.285495 15.9048 0.300844 15.9109 0.316193 15.9201C0.334612 15.9324 0.356101 15.9416 0.37759 15.9508C0.399079 15.96 0.417498 15.9631 0.438987 15.9692C0.457406 15.9754 0.472755 15.9815 0.491174 15.9846C0.531082 15.9938 0.57099 15.9969 0.610898 15.9969H5.07444C5.41213 15.9969 5.68841 15.7206 5.68841 15.3829C5.68841 15.0452 5.41213 14.7689 5.07444 14.7689H2.09363L5.82655 11.0391C6.066 10.7996 6.066 10.4098 5.82655 10.1703C5.58711 9.93086 5.19724 9.93086 4.95779 10.1703L1.22794 13.9063V11.1281C1.22794 10.7904 0.95165 10.5141 0.613968 10.5141C0.276285 10.5141 0 10.7904 0 11.1281V15.386Z"
                  fill="#2E3192"
                />
                <path
                  d="M5.72818 0.613968C5.72818 0.276285 5.45189 0 5.11421 0H0.613828C0.57392 0 0.534011 0.00306953 0.494103 0.0122791C0.475684 0.0153489 0.460335 0.0214889 0.441916 0.0276286C0.420427 0.0337683 0.398939 0.0399078 0.38052 0.0460475C0.359031 0.055257 0.340612 0.0644666 0.322193 0.076746C0.306843 0.0859555 0.288424 0.0920952 0.273075 0.104375C0.205539 0.150422 0.147212 0.205679 0.104234 0.273215C0.0950248 0.288565 0.0888852 0.303914 0.0796757 0.319263C0.0704661 0.337682 0.0581867 0.356101 0.0489772 0.37759C0.0397676 0.399079 0.036698 0.417498 0.0305583 0.438987C0.0244186 0.457406 0.0182786 0.472755 0.0152087 0.491174C0.00599922 0.531082 0.00292969 0.57099 0.00292969 0.610898V5.29854C0.00292969 5.63622 0.279215 5.91251 0.616897 5.91251C0.95458 5.91251 1.23087 5.63622 1.23087 5.29854V2.09363L4.86862 5.73139C4.98835 5.85111 5.14491 5.91251 5.30147 5.91251C5.45803 5.91251 5.61459 5.85111 5.73432 5.73139C5.97377 5.49194 5.97377 5.10207 5.73432 4.86262L2.09349 1.22794H5.11421C5.45496 1.22794 5.72818 0.95165 5.72818 0.613968Z"
                  fill="#2E3192"
                />
                <path
                  d="M10.2688 5.73125C10.3885 5.85097 10.5451 5.91237 10.7016 5.91237C10.8582 5.91237 11.0148 5.85097 11.1345 5.73125L14.7723 2.09349V5.2984C14.7723 5.63608 15.0485 5.91237 15.3862 5.91237C15.7239 5.91237 16.0002 5.63608 16.0002 5.2984V0.613828C16.0002 0.57392 15.9971 0.534011 15.9879 0.494103C15.9848 0.475684 15.9787 0.460335 15.9726 0.441916C15.9664 0.420427 15.9603 0.398939 15.9541 0.38052C15.9449 0.359031 15.9327 0.340612 15.9234 0.322193C15.9142 0.306843 15.9081 0.291494 15.8989 0.276145C15.8528 0.208609 15.7976 0.150282 15.73 0.107304C15.7147 0.0980947 15.6963 0.0888852 15.6809 0.0796757C15.6625 0.0704661 15.6441 0.0581867 15.6226 0.0489772C15.6011 0.0397676 15.5796 0.036698 15.5581 0.0305583C15.5397 0.0244186 15.5244 0.0182786 15.5059 0.0152087C15.466 0.00599922 15.4261 0.00292969 15.3862 0.00292969H10.8981C10.5604 0.00292969 10.2842 0.279215 10.2842 0.616897C10.2842 0.95458 10.5604 1.23087 10.8981 1.23087H13.9066L10.2688 4.86555C10.0263 5.105 10.0263 5.4918 10.2688 5.73125Z"
                  fill="#2E3192"
                />
                <path
                  d="M10.1732 11.0394L13.9062 14.7723H10.9745C10.6368 14.7723 10.3605 15.0486 10.3605 15.3863C10.3605 15.724 10.6368 16.0003 10.9745 16.0003H15.3889C15.4288 16.0003 15.4687 15.9972 15.5086 15.988C15.527 15.9849 15.5424 15.9788 15.5608 15.9727C15.5823 15.9665 15.6038 15.9604 15.6222 15.9542C15.6437 15.945 15.6621 15.9327 15.6805 15.9235C15.6959 15.9143 15.7143 15.9082 15.7266 15.899C15.7941 15.8529 15.8524 15.7946 15.8954 15.7301C15.9046 15.7148 15.9108 15.6994 15.92 15.6841C15.9323 15.6657 15.9415 15.6442 15.9507 15.6227C15.9599 15.6012 15.963 15.5828 15.9691 15.5613C15.9752 15.5429 15.9814 15.5275 15.9844 15.5091C15.9937 15.4692 15.9967 15.4293 15.9967 15.3894V11.1254C15.9967 10.7877 15.7204 10.5114 15.3828 10.5114C15.0451 10.5114 14.7688 10.7877 14.7688 11.1254V13.9036L11.0389 10.1737C10.7995 9.93428 10.4096 9.93428 10.1702 10.1737C9.93072 10.4132 9.93072 10.8 10.1732 11.0394Z"
                  fill="#2E3192"
                />
              </svg>
    )
}