import { Button, Col, Row, Tag } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { IStatus } from "../../../../interfaces/quiz";
import useQuizStore from "../../../../store/quizStore";
import QuePaper from "../QuePaper";
import SectionSubmit from "../SectionSubmit";
import QuizCounts from "./quizCounts";

const SectionIndex = ({
  handleScroll,
  title,
  pageId,
  onClickSubmit,
  isSmallScreen,
  scalingFactor,
  lastSectionId,
  setPageId,
  setCurrentSectionTitle,
}) => {
  const { quiz, status, currentSectionQues } = useQuizStore((status) => status);

  const [isQueDrawerOpen, setIsQueDrawerOpen] = useState(false);

  const [questions, setQuestions] = useState(quiz?.questions);
  const { activeTabId } = useQuizStore();

  const [isQuizSubmit, setIsQuizSubmit] = useState(false);
  const handleQuestion = (position: number) => {
    handleScroll(position);
  };

  const handleModal = (val: string) => {
    setIsQuizSubmit(!isQuizSubmit);
  };

  const getColorClass = (status: IStatus) => {
    if (status?.bookmark) return "question-bookmarked";
    if (status?.attempt?.length !== 0) return "question-attempted";
    if (status?.visited) return "currentQuestion";
    return "question-default";
  };
  
   return (
    <>
      <div style={{ height: "84%" }}>
        <div style={{ height: "100%" }}>
          <div style={{ maxHeight: "19%", padding: "3.5%" }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.2em",
                color: "#5B5B5B",
                marginBottom: "0.3%",
              }}
            >
              Select which Question number you want to attempt.
            </p>
            {title && (
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1.2em",
                  color: "#5B5B5B",
                  marginBottom: "0%",
                }}
              >
                Section- {title}
              </p>
            )}
          </div>

          <QuizCounts />

          <div style={{ height: "63%", overflowY: "scroll" }}>
            <Row style={{ paddingBottom: "5%", paddingTop: "3%" }}>
              {(quiz?.is_sectional === 1 ? currentSectionQues : questions)?.map(
                (item, index) => {
                  const currentStatus: IStatus = status[item.id];
                  const className = getColorClass(currentStatus);
                  return (
                    <Col key={index} xl={4} lg={6} md={6} sm={4} xs={6}>
                      <IndexBtn
                        className={
                          (index + 1 === pageId && "activeQues") || className
                        }
                        onClick={() => handleQuestion(index + 1)}
                      >
                        {index + 1}
                      </IndexBtn>
                    </Col>
                  );
                }
              )}
            </Row>
          </div>
        </div>
      </div>

      <Row justify={"center"} style={{ height: "13%" }}>
        <Button
          style={{ width: "90%", fontWeight: "600" }}
          onClick={() => setIsQueDrawerOpen(true)}
          size={scalingFactor >= 1.5 ? "middle" : "large"}
        >
          Question Paper
        </Button>

        <SectionSubmit
          lastSectionId={lastSectionId}
          scalingFactor={scalingFactor}
          setPageId={setPageId}
          setCurrentSectionTitle={setCurrentSectionTitle}
        />

        {quiz?.meta?.is_section_timer && activeTabId === lastSectionId && (
          <Button
            style={{
              fontWeight: 800,
              width: "90%",
              background: "#2E3192",
              color: "white",
              marginTop: "3%",
            }}
            onClick={() => onClickSubmit()}
            size={scalingFactor >= 1.5 ? "middle" : "large"}
          >
            Submit
          </Button>
        )}

        {!quiz?.meta?.is_section_timer && (
          <Button
            style={{
              fontWeight: 800,
              width: "90%",
              background: "#2E3192",
              color: "white",
              marginTop: "3%",
            }}
            onClick={() => onClickSubmit()}
            size={scalingFactor >= 1.5 ? "middle" : "large"}
          >
            Submit
          </Button>
        )}
      </Row>

      <QuePaper
        isQueDrawerOpen={isQueDrawerOpen}
        setIsQueDrawerOpen={setIsQueDrawerOpen}
      />
    </>
  );
};

const IndexBtn = styled(Button)`
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 2px;
    box-shadow: 0px 1px 2px gray;
    margin-left: 0.8rem;
    margin-top: 0.8rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: '#2e3192';
    font-size: 1.4
    font-weight: 800
`;

export default SectionIndex;
